<template>
  <b-modal
    @hide="clearFields"
    @ok="submitForm"
    ok-title="Submit"
    ok-only
    footer-class="d-flex justify-content-center"
    :title="title"
    v-model="showPrompt"
    centered
  >
    <b-form class="px-4 py-2">
      <b-form-group class="" label="Current Password *" label-for="current-password" v-if="isConfirmCurrentPassword">
        <b-form-input
          id="current-password"
          class="w-100"
          type="password"
          name="currentPassword"
          placeholder="Current Password *"
          v-model="currentPassword"
          autocomplete="off"
        />
      </b-form-group>

      <b-form-group class="" label="New Password *" label-for="new-password">
        <b-form-input
          id="new-password"
          ref="password"
          class="w-100"
          type="password"
          name="newPassword"
          placeholder="New Password *"
          v-model="$v.newPassword.$model"
          autocomplete="off"
        />
        <small class="text-danger w-100" v-show="!$v.newPassword.passwordValid">
          Password must have at least 8 characters, of which at least a lower
          case, an UPPER CASE, and a number.
        </small>
        <b-tooltip
          placement="bottomright"
          target="new-password"
          triggers="hover focus"
          custom-class="text-left"
      >
          <div class="text-left w-100">
            <p>
              * Password must have at least 8 characters
            </p>
            <p>
              * Password must have at least a lower case, an UPPER CASE letters and a number
            </p>
            <p>
              * Password can have these special characters (!@#$%^)
            </p>
          </div>
        </b-tooltip>
        <small
          class="text-danger w-100"
          v-show="newPassword != null && !$v.newPassword.required"
        >
          Password cannot be blank
        </small>
      </b-form-group>
      <b-form-group
        class=""
        label="Retype Password *"
        label-for="retype-password"
      >
        <b-form-input
          id="retype-password"
          class="w-100"
          type="password"
          name="retypePassword"
          placeholder="Retype Password *"
          v-model="$v.retypePassword.$model"
          autocomplete="off"
        />
        <small
          class="text-danger w-100"
          v-show="!$v.retypePassword.sameAsPassword"
        >
          Passwords do not match
        </small>
        <small
          class="text-danger w-100"
          v-show="retypePassword != null && !$v.retypePassword.required"
        >
          Password cannot be blank
        </small>
      </b-form-group>

      <b-form-group v-if="signout">
        <b-form-checkbox v-model="needSignout">
          Sign out of all devices?
        </b-form-checkbox>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required, sameAs } = require("vuelidate/lib/validators");
import { passwordValid } from "@/helper/validators";

export default {
  props: {
    title: {
      type: String,
      default: "Change Password",
    },
    activePrompt: Boolean,
    submitPrompt: Function,
    signout: Function,
    isConfirmCurrentPassword: Boolean
  },
  data() {
    return {
      newPassword: null,
      retypePassword: null,
      showPrompt: false,
      needSignout: false,
      currentPassword: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    newPassword: { required, passwordValid },
    retypePassword: { required, sameAsPassword: sameAs("newPassword") },
  },
  computed: {
    validateForm() {
      return !this.$v.newPassword.$invalid && !this.$v.retypePassword.$invalid;
    },
  },
  methods: {
    clearFields() {
      this.newPassword = null;
      this.retypePassword = null;
      this.$emit("close");
    },
    submitForm() {
      if (this.newPassword == null) this.newPassword = "";
      if (this.retypePassword == null) this.retypePassword = "";
      this.$v.$touch();
      if (this.$v.$invalid) {
        // console.log("$v:", this.$v);
        return;
      }
      if(this.isConfirmCurrentPassword)
        this.submitPrompt(this.currentPassword, this.newPassword, this.retypePassword);
      else
        this.submitPrompt(this.newPassword);
      if (this.needSignout) {
        this.signout();
      }
      this.clearFields();
    },
  },
  watch: {
    activePrompt() {
      this.showPrompt = this.activePrompt;
    },
  },
};
</script>