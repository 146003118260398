<template>
  <div>
    <b-card style="padding: 25px">
      <!-- Name and Photo -->
      <div class="d-flex">
        <div @click="onImgClick" class="custom-image-upload-update border">
          <img
            class="custom-image custom-image-update"
            v-if="currentImgUrl"
            :src="currentImgUrl"
          />
          <div class="custom-image-overlay">
            <span class="custom-image-overlay-text">Change</span>
          </div>
          <b-form-file
            :id="`image-upload`"
            style="display: none"
            v-model="file"
            @input="onFileInput"
          />
        </div>
        <div class="ml-2 position-relative w-100">
          <h3>{{userData.first_name}} {{userData.last_name}}</h3>
          <div class="position-absolute " style="bottom: 0">
            <b-button variant="success" @click="passwordModalShown = true" class="font-small-3">Change Password</b-button>
          </div>
        </div>
      </div>

      <!-- Info -->
      <div class="row mt-4 text-12px">
        <div class="col-3 mb-2">
          <p class="mb-small text-heading-color">Name</p>
          <p class="my-border my-shadow rounded-5  px-1 py-50">{{userData.first_name}} {{userData.last_name}}</p>
        </div>
        
        <div class="col-3 mb-2">
          <p class="mb-small text-heading-color">Email</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{userData.email}}</p>
        </div>

        <div class="col-3 mb-2" v-if="!isAdmin">
          <p class="mb-small text-heading-color">Department</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{department}}</p>
        </div>

        <div class="col-3  mb-2" v-if="!isAdmin">
          <p class="mb-small text-heading-color">Manager</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{manager}}</p>
        </div>

        <div class="col-3 mb-2">
           <p class="mb-small text-heading-color">Phone</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{userData.personal_info.mobile_phone}}</p>
        </div>

        <div class="col-3 mb-2" v-if="!isAdmin && employee.employee_info">
          <p class="mb-small text-heading-color">Date Hired</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{employee.employee_info.hired_at.split('-').reverse().join('/')}}</p>
        </div>

        <div class="col-3 mb-2">
          <p class="mb-small text-heading-color">Date of birth</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{userData.personal_info.date_of_birth.split('-').reverse().join('/')}}</p>
        </div>

        <div class="col-3 mb-2" v-if="!isAdmin">
          <p class="mb-small text-heading-color">Employee ID</p>
          <p class="my-border my-shadow rounded-5 px-1 py-50" >{{userData.first_name}} {{userData.last_name}}</p>
        </div>
      </div>
    </b-card>

     <change-password-modal
      title="Change Password"
      :activePrompt="passwordModalShown"
      :submitPrompt="changePassword" 
      isConfirmCurrentPassword
      @close="passwordModalShown = false"
    />

  </div>
</template>

<script>

import axios from '@axios'
import moment from 'moment'
import { notifyError, notifySuccess } from '@/helper/notifications'
import ChangePasswordModal from "@/components/modals/ChangePasswordModal"

export default {
  components:{
    ChangePasswordModal
  },

  data() {
    return {
      file: null,
      passwordModalShown: false,
      employee: {}
    };
  },
  computed: {
    isAdmin(){
      return this.userData.roles.roles.includes('Admin')
    },
    currentImgUrl() {
      return (this.file && URL.createObjectURL(this.file)) || this.userData?.photo_url
    },

    department(){
      return this.userData.user_groups[0].name
    },

    manager(){
      return this.employee.user_groups ? this.employee.user.user_groups[0].manager : ''
    },

    userData(){
      return JSON.parse(localStorage.getItem("userData"))
    }
  },
  methods: {
    moment,

    changePassword(current, new_pass, retype_pass) {
      let loading = this.$loading.show()
       axios.patch(`${process.env.VUE_APP_API_URL}users/changePassword`, {user: { currentPassword: current, newPassword: new_pass, repeatPassword: retype_pass }})
        .then(res => {
          notifySuccess("Success", "Changed password successfully.");
        }).catch(err => {
          notifyError("Error", err.response.data?.errors?.password[0] || "Failed to change password")
        }).finally(() => {
          loading.hide()
        })
    },


    onFileInput(file){
      let form = new FormData()
      form.append("photo", file);
      axios.post(process.env.VUE_APP_API_URL + 'users/' + this.userData.id + '/upload_photo', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        notifySuccess("Success", "Successfully uploaded photo.")
      }).catch(err => {
        notifyError("Error", "Failed to upload photo")
      })
    },

    onImgClick() {
      document.getElementById(`image-upload`).click();
    },
  },

  mounted() {
    if(!this.isAdmin)
      axios.get(`${process.env.VUE_APP_API_URL}employees?user_id=${this.userData.id}`).then(res =>{
        this.employee = res.data[0]
      })
  },
};
</script>

<style scoped>

.custom-image-upload-update {
  position: relative;
  width: 100px;
  height: 100px;
  background: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
}
.custom-image-upload-update:hover .custom-image-overlay {
  opacity: 0.5;
  cursor: pointer;
}
.custom-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #f8f8f8;
}
.custom-image-overlay-text {
  color: black;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  overflow: hidden;
}
.custom-image-upload {
  /* min-height: 15rem;
  min-width: 15rem;
  max-height: 15rem;
  max-width: 15rem; */
  width: 12rem;
  height: 12rem;
  background: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
}
.custom-image {
  max-width: 100%;
  border-radius: 10px;
}
</style>